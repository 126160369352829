var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: this.name } },
    [
      _c("ReadyForInvoicingByFilterTable", {
        attrs: {
          id: this.name,
          allFilters: _vm.allFilters,
          invoiceOptions: _vm.invoiceOptions,
          allTransportEventFilters: _vm.allTransportEventFilters
        },
        on: {
          "start-invoicing": function(filterId, invoiceOptionsId) {
            return _vm.startPurchaseInvoicing(filterId, invoiceOptionsId)
          },
          "update-invoicing-options": function(invoicingOptions) {
            return _vm.updateInvoicingOptions(invoicingOptions)
          },
          "create-invoicing-options": function(invoicingOptions) {
            return _vm.createInvoicingOptions(invoicingOptions)
          },
          "delete-invoicing-options": function(invoicingOptionsId) {
            return _vm.deleteInvoicingOptions(invoicingOptionsId)
          },
          "create-invoicing-filter": function(invoicingFilter) {
            return _vm.createInvoicingFilter(invoicingFilter)
          },
          "update-invoicing-filter": function(
            invoicingFilterId,
            invoicingFilter
          ) {
            return _vm.updateInvoicingFilter(invoicingFilterId, invoicingFilter)
          },
          "delete-invoicing-filter": function(invoicingFilterId) {
            return _vm.deleteInvoicingFilter(invoicingFilterId)
          }
        }
      }),
      _c("InvoicingBatchTable", {
        attrs: { id: this.name, batchRows: _vm.batchRows },
        on: {
          "show-log-messages": function(item) {
            return _vm.toggleLogMessageDialog(item)
          },
          "verify-batch": function(batch) {
            return _vm.verifyPurchaseInvoiceBatch(batch)
          }
        }
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
          ),
          _c("BatchLogMessagesDialog", {
            attrs: { messages: _vm.logMessages, visible: _vm.isDialogVisible },
            on: {
              close: function($event) {
                _vm.isDialogVisible = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }